import _last from 'lodash/last';
import {all, call, put, select, takeLatest} from 'redux-saga/effects';

import {FileInformation} from '../../models/FileInformation';
import {ReduxAction} from '../../models/ReduxAction';
import {User} from '../../models/User';
import {AssociatedTypes} from '../../models/enumerations/AssociatedTypes';
import {DocumentTypeCodes} from '../../models/enumerations/DocumentTypeCodes';
import IndexedDB, {IndexedDBKeys} from '../../services/indexedDB';
import request, {ApiResponse} from '../../services/request';
import {showErrorMessages} from '../../utils/showErrorMessages';
import {updateWorkOrderInStoreAndIndexedDB} from '../../utils/updateWorkOrderInStoreAndIndexedDB';
import {fetchWorkOrder} from '../work-order/work-order.actions';
import {WorkOrderState} from '../work-order/work-order.reducer';
import {selectWorkOrderState} from '../work-order/work-order.selectors';

import {createFileResponse, deleteFileResponse, fileResponse, filesError, filesRequest, filesResponse} from './file.actions';
import {CREATE_FILE, CreateFile, DELETE_FILE, FETCH_FILES, FetchFiles} from './file.types';

export function* fetchFiles({payload}: ReduxAction<FetchFiles>) {
    yield put(filesRequest());
    // Yield an array with the last known file count of blank placeholders so the count is the UI is correct.
    yield put(fileResponse(new Array(payload.fileCount)));
    let files: FileInformation[] = [];

    // Add the cached files.
    if (payload.useCachedFiles) {
        files = yield call(IndexedDB.getMany, IndexedDBKeys.FILES);
        yield put(fileResponse(files));
        yield call(IndexedDB.createMany, IndexedDBKeys.FILES, files);
    }

    // Add placeholders if there are more files than cached using negative IDs for easy identification.
    let fakeID = -+new Date();
    let blob: Blob | undefined = undefined;
    if (files.length < payload.fileCount) {
        blob = yield fetch(`${window.location.origin}/placeholder.jpg`).then((response) => response.blob().then((b) => b));
        const placeholderCount = payload.fileCount - files.length;
        for (let i = 0; i < placeholderCount; i++) {
            files.push({src: blob, ID: fakeID} as FileInformation);
            fakeID--;
        }

        yield put(fileResponse(files));
        yield call(IndexedDB.createMany, IndexedDBKeys.FILES, files);
    }

    const {data, error}: ApiResponse<FileInformation[]> = yield call(request, {
        url: '/files',
        method: 'get',
        params: {
            associatedObjectID: payload.associateObjectID,
            associatedTypeID: payload.associatedTypeID,
        },
    });

    if (error) {
        yield put(filesError(error.message));
        yield showErrorMessages(error.messages);
    }

    if (data) {
        // Remove cached files if the endpoint didn't return them.
        files = files.filter(({ID}) => ID < 1 || data.map(({ID}) => ID).includes(ID));

        // Now that we have an accurate count, re-evaluate the placeholders, adding or removing so the counts match.
        if (data.length > files.length) {
            if (!blob) {
                blob = yield fetch(`${window.location.origin}/placeholder.jpg`).then((response) => response.blob().then((b) => b));
            }
            const placeholderCount = data.length - files.length;
            for (let i = 0; i < placeholderCount; i++) {
                files.push({src: blob, ID: fakeID} as FileInformation);
                fakeID--;
            }

            yield put(fileResponse(files));
            yield call(IndexedDB.createMany, IndexedDBKeys.FILES, files);
        } else if (data.length < files.length) {
            const placeholdersToRemove = files.length - data.length;
            for (let i = 0; i < placeholdersToRemove; i++) {
                files.splice(
                    files.findIndex(({ID}) => ID < 0),
                    1,
                );
            }
            yield put(fileResponse(files));
            yield call(IndexedDB.createMany, IndexedDBKeys.FILES, files);
        }

        // Replace the placeholder IDs with the real IDs.
        const uncachedIDs = data.map(({ID}) => ID).filter((id) => !files.map(({ID}) => ID).includes(id));
        for (let i = 0; i < uncachedIDs.length; i++) {
            const index = files.findIndex(({ID}) => ID < 0);
            files[index] = {...files[index], ID: uncachedIDs[i]};
        }

        // If there are somehow any leftover placeholders, remove them.
        if (files.some(({ID}) => ID < 0)) {
            files = files.filter(({ID}) => ID > 0);
            yield put(fileResponse(files));
            yield call(IndexedDB.createMany, IndexedDBKeys.FILES, files);
        }

        // Get ids of files that were not cached.
        // This is called after deleting a file and sometimes the deleted one is still in the list but the response is less than a kilobyte.
        for (const ID of uncachedIDs) {
            const response: ApiResponse<Blob> = yield call(request, {
                url: `/files/download/${ID}`,
                method: 'get',
                responseType: 'blob',
            });
            const fileID = +_last(response.request?.responseURL.split('/'))!;
            const file = data.find((file) => file.ID === fileID);

            if (file && response.data) {
                let index = files.findIndex(({ID}) => ID === fileID);
                if (index > -1) {
                    files[index] = {
                        ...file,
                        src: response.data,
                    };
                } else {
                    files.push({
                        ...file,
                        src: response.data,
                    });
                }

                yield put(fileResponse(files));
                yield call(IndexedDB.createMany, IndexedDBKeys.FILES, files);
            }
        }

        yield put(filesResponse(files));
        yield call(IndexedDB.createMany, IndexedDBKeys.FILES, files);
    }
}

function* createFile({payload}: ReduxAction<CreateFile>) {
    yield put(filesRequest());

    // Immediately respond with the unuploaded file as a placeholder.
    const tempFile: FileInformation = {
        URL: String(payload.src),
        FileExtension: '',
        FileCategory: '',
        FileLocation: '',
        FileSize: 0,
        OriginalFileName: '',
        StoredFileName: '',
        CreateDate: '',
        CreatedByUser: {} as User,
        DocumentTypeCode: payload.type ?? DocumentTypeCodes.PhotoOfDamage,
        Description: '',
        Checksum: '',
        ChecksumType: '',
        FileID: 0,
        ImageReference: '',
        CompanyID: 0,
        ID: +new Date(),
        IsDeleted: false,
    };
    yield put(createFileResponse(tempFile));

    const {data, error}: ApiResponse<FileInformation> = yield call(request, {
        data: payload.file,
        url: '/files',
        method: 'post',
        headers: {
            'Content-Type': 'multipart/form-data',
            Accept: 'application/json',
            type: 'formData',
        },
        params: {
            type: payload.type,
            associatedObjectID: payload.associateObjectID,
            associatedTypeID: payload.associatedTypeID,
        },
    });

    // Delete the placeholder.
    const files: FileInformation[] = yield call(IndexedDB.getMany, IndexedDBKeys.FILES);
    yield put(deleteFileResponse(files));

    if (error) {
        yield all([put(filesError(error.message)), showErrorMessages(error.messages)]);
    }

    if (data) {
        yield put(
            createFileResponse({
                ...data,
                URL: String(payload.src),
            }),
        );
        yield call(IndexedDB.createMany, IndexedDBKeys.FILES, [
            ...files,
            {...data, src: yield fetch(String(payload.src)).then((response) => response.blob().then((b) => b))} as FileInformation,
        ]);

        if (
            payload.workOrderID &&
            (payload.associatedTypeID === AssociatedTypes.WorkOrder || payload.associatedTypeID === AssociatedTypes.WorkOrderRepairLine)
        ) {
            const {workOrders}: WorkOrderState = yield select(selectWorkOrderState);

            const workOrder = workOrders.find(({ID}) => ID === payload.workOrderID);

            if (workOrder) {
                yield updateWorkOrderInStoreAndIndexedDB({...workOrder, FileList: [...workOrder.FileList, data]});
                yield put(fetchWorkOrder({workOrderID: payload.workOrderID}));
            }
        }
    }
}

function* deleteFile({payload}: ReduxAction) {
    yield put(filesRequest());

    // Immediately update the store. If the request fails, re-add the file.
    const allFiles: FileInformation[] = yield call(IndexedDB.getMany, IndexedDBKeys.FILES);
    const filesToKeep = allFiles.filter(({ID}) => ID !== payload.fileID);

    yield put(deleteFileResponse(filesToKeep));
    yield call(IndexedDB.createMany, IndexedDBKeys.FILES, filesToKeep);

    const {data, error}: ApiResponse<FileInformation> = yield call(request, {
        url: `/files/${payload.fileID}`,
        method: 'delete',
    });

    if (error) {
        yield call(IndexedDB.createMany, IndexedDBKeys.FILES, allFiles);
        yield all([put(deleteFileResponse(allFiles)), put(filesError(error.message)), showErrorMessages(error.messages)]);
    } else if (data) {
        if (payload.associatedTypeID === AssociatedTypes.WorkOrder || payload.associatedTypeID === AssociatedTypes.WorkOrderRepairLine) {
            const {workOrders}: WorkOrderState = yield select(selectWorkOrderState);

            const workOrder = workOrders.find(({ID}) => ID === payload.workOrderID);

            if (workOrder) {
                yield updateWorkOrderInStoreAndIndexedDB({...workOrder, FileList: workOrder.FileList.filter(({ID}) => ID !== data.ID)});
                yield put(fetchWorkOrder({workOrderID: payload.workOrderID}));
            }
        }
    }
}

export default function* fileRootSaga() {
    yield takeLatest<ReduxAction>(FETCH_FILES, fetchFiles);
    yield takeLatest<ReduxAction>(DELETE_FILE, deleteFile);
    yield takeLatest<ReduxAction>(CREATE_FILE, createFile);
}
