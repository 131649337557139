import {CircularProgress} from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
    margin: 0 5px 5px 5px;

    @media (min-width: 600px) {
        max-width: 600px;
        margin: auto;
    }
`;

export const GalleryWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
`;

export const ImageItem = styled.div`
    cursor: ${(props) => (props.onClick ? 'default' : 'not-allowed')};
    margin: 0.1rem;
    flex: 1 0 49%;
    max-width: 50%;
    height: 200px;
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
`;

export const Count = styled.h1`
    align-items: center;
    justify-content: center;
    display: flex;
    color: ${(props) => props.theme.palette.primary.main};
    font-weight: 400;
`;

export const DocumentType = styled.h1`
    align-items: center;
    justify-content: center;
    display: flex;
    color: ${(props) => props.theme.palette.primary.main};
    font-weight: 400;
    margin: 5px;
`;

export const AddButton = styled.div`
    border: none;
    background-color: ${(props) => props.theme.palette.primary.main};
    color: #fff;
    margin: auto;
    max-width: 600px;
    display: flex;
    justify-content: center;

    svg {
        font-size: 40px;
    }
`;

export const Input = styled.input`
    opacity: 0;
    position: absolute;
    z-index: -1;
    overflow: hidden;
`;

export const Label = styled.label`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9px 49px;
    cursor: pointer;
    width: 100%;
`;

export const ViewModalContainer = styled.div`
    background-color: #fff;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
`;

export const ImageContent = styled.div`
    padding: 0;
    position: relative;
    img {
        width: 100%;
        max-height: 375px;
        display: block;
        object-fit: contain;
    }
`;

export const DeleteWrap = styled.div`
    background: #bdbdbd;
    width: 100%;
    text-align: center;

    svg {
        font-size: 30px;
        color: #fff;
        margin-top: 5px;
    }
`;

export const Navigation = styled.div`
    display: flex;
    justify-content: space-between;
    max-width: 600px;
    margin: 5px 0;
`;

export const NavBtn = styled.button`
    border: none;
    background: #bdbdbd;
    width: 31%;
    text-align: center;

    svg {
        font-size: 90px;
        color: #fff;
    }
`;

export const HeaderTitle = styled.h2`
    color: ${({theme}) => theme.palette.primary.main};
`;

export const Loader = styled(CircularProgress)`
    position: absolute;
    margin-top: 60px;

    @media (min-width: 600px) {
        margin-left: 110px;
    }

    @media (max-width: 600px) {
        margin-left: calc(25% - 40px);
    }
`;
